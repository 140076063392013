@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #f5f5f5;
}

* {
  font-family: Roboto, serif;
}

/* TODO don't like overriding from here but having difficulty overriding this in theme and separate component */
a.MuiLink-root {
  text-decoration: none;
  cursor: pointer;
  z-index: 0;
}

a.MuiLink-root.unstyled-link {
  text-decoration: none !important;
  cursor: pointer;
  z-index: 0;
}

a.MuiLink-root:visited {
  text-decoration: underline solid rgb(107, 26, 72);
}

a.MuiLink-root:hover,
a.MuiLink-root:focus {
  text-decoration: underline solid rgb(36, 61, 162);
}

a.MuiLink-root:hover:visited,
a.MuiLink-root:focus:visited {
  text-decoration: underline solid rgb(107, 26, 72);
}

a.MuiLink-root:focus-visible {
  outline: rgb(0, 133, 117) solid 0.1875rem;
}

.Toastify {
  z-index: 99999;
}

.Toastify__toast-theme--dark {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0.25rem;
}

.Toastify__toast-body {
  text-align: center;
}

#launcher {
  bottom: 14px !important;
  z-index: 999 !important;
}

.ag-body-horizontal-scroll.ag-scrollbar-invisible {
  pointer-events: all !important;
}

.selected {
  z-index: 1000 !important;
}

.ql-toolbar.ql-snow {
  background: rgba(58, 67, 105, 0.04) !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-container.ql-snow {
  border-bottom: none;
}

/* chat */
.str-chat {
  height: auto !important;
  max-height: auto !important;
}

.str-chat__list .str-chat__reverse-infinite-scroll {
  padding-top: 0px !important;
}

.str-chat__date-separator {
  padding: 12px !important;
}

.str-chat__li--top,
.str-chat__li--single {
  padding-top: 0px;
}

.str-chat__li--bottom {
  padding-bottom: 0px;
}

.str-chat__thread-start {
  margin-bottom: 6px;
}

.livestream.str-chat .str-chat__thread--full,
.str-chat .str-chat__thread--full {
  width: 500px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px,
    rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
}

.livestream.str-chat .str-chat__list--thread,
.str-chat .str-chat__list--thread {
  padding: 0px;
}

[id*="__oneof_select"] ul.MuiMenu-list > li {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid rgba(33, 33, 33, 0.08);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025rem;
  font-weight: 500;
  margin: 8px;
}

[id*="__oneof_select"] ul.MuiMenu-list > li:hover {
  background-color: rgba(0, 133, 117, 0.08);
}

[id*="__oneof_select"] ul.MuiMenu-list > li:first-child {
  margin-top: 0;
}

[id*="__oneof_select"] ul.MuiMenu-list > li:last-child {
  margin-bottom: 0;
}

.MuiList-root {
  max-height: 350px;
}
.schemas-holder .MuiList-root {
  max-height: calc(100vh - 500px);
  min-height: 200px;
}

.uppy-u-reset.uppy-c-btn.uppy-StatusBar-actionBtn {
  height: 100px;
}

.ag-theme-material .ag-row-hover:not(.ag-full-width-row)::before,
.ag-theme-material .ag-row-hover.ag-full-width-row.ag-row-group::before {
  background: rgba(0, 133, 117, 0.04) !important;
}

.ag-theme-material .ag-header-cell.ag-header-active {
  background: rgba(0, 133, 117, 0.04) !important;
}

.ag-theme-material .ag-header-cell {
  border-right: none !important;
}

.d-none,
.ag-watermark {
  display: none !important;
}

#root .ag-theme-material .ag-body-viewport .ag-row,
#root .ag-theme-material .ag-body-viewport .ag-row.ag-row-focus {
  border-top-color: rgba(0, 0, 0, 0.12) !important;
  border-bottom-color: rgba(0, 0, 0, 0.12) !important;
}

#root .ag-theme-material .ag-body-viewport .ag-row.ag-row-focus .ag-cell {
  border-color: transparent !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(9, 119, 113) !important;
  border-width: 1px !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}

.chat-auto-complete .MuiOutlinedInput-root .MuiAutocomplete-input,
.custom-autocomplete .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0 4px !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  box-shadow: none !important;
}

.MuiAutocomplete-popper.custom-autocomplete-dropdown > div {
  box-shadow: none !important;
  border-radius: 0 !important;
}

.MuiAutocomplete-popper.custom-autocomplete-dropdown .MuiAutocomplete-option {
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  padding: 12px 16px !important;
  border-radius: 8px !important;
  margin: 0 0 12px !important;
  display: block !important;
}

.css-8je8zh-MuiTouchRipple-root {
  display: none !important;
}

.css-fb88jp-MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused,
.css-fb88jp-MuiAutocomplete-listbox .MuiAutocomplete-option:hover {
  background: rgba(21, 185, 168, 0.16) !important;
}
.css-fb88jp-MuiAutocomplete-listbox .MuiAutocomplete-option {
  margin: 2px 0 0 !important;
  font-size: 14px;
}
.chatdrop ul li {
  display: flex;
  justify-content: space-between;
  min-width: 120px;
  font-size: 13px;
  padding: 6px 8px;
  line-height: 1.25rem;
}
.chatdrop ul {
  padding: 0;
}
.chatdrop ul li.delete {
  color: rgb(162, 16, 62);
}
.chatdrop ul li svg {
  width: 12px;
  height: 12px;
}
